import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery } from 'gatsby';
import Post from '../components/post'
import AboutusBG from "../images/optinly-about-us-banner.png"

var h3center ={
    h3: {
        textAlign: "center"
      },
}

const AboutUs = () => {
  return (
    <Layout>

      <div className="breadcrumb_top" style={{backgroundImage:`url(${AboutusBG})`,backgroundRepeat: 'no-repeat',backgroundPosition:'center',backgroundSize:'cover'}}>
          <div className="container">
              <div className="row justify-content-center align-items-center">
                  <div className="col-md-10">
                      <h2 className="text-white">About Us</h2>
                      <p className="text-white"><strong>We Help Businesses Monetize Traffic & Improve Conversions Every Day! <br/>Witness Your Email Lists Grow Rapidly.</strong></p>
                  </div>
              </div>
          </div>
      </div>

      <StaticQuery
      query={AboutUsQuery}
      render={data => {
        return (
          <div className="legal-info" style={h3center}>
              <Post
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.frontmatter.description}
                keywords={data.markdownRemark.frontmatter.keywords}
                excerptData={data.markdownRemark.htmlAst}
              />
          </div>
      )
    }}
    />   
    
    
  </Layout>
    )
  }
const AboutUsQuery = graphql`
 query AboutUsQuery{
  markdownRemark(frontmatter: {path: {eq: "/about-us"}}){
    htmlAst
    frontmatter{
      title
      description
      keywords
      path
    }
  }
} 
`
export default AboutUs


// optinly

{/* <script id="optinly_script" async="true" data-app_id='428e8c4e-ec3b-4ed2-bd22-10bee16892f3'   src='https://cdn.optinly.net/v1/optinly.js'> </script> */}

// GoogleTagManager(NoScript)

// <!-- Google Tag Manager (noscript) -->
// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N2VWKDG"
// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) -->



// analatics

// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=UA-215307741-3"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'UA-215307741-3');
// </script>